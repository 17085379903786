import { SecondaryText, Text } from 'components/ui/Text';

export function OutputTaxTooltipBody({
  currencySymbol
}: {
  currencySymbol?: string;
}) {
  return (
    <>
      <SecondaryText>Exact input only</SecondaryText>

      <div className='mx-0 my-3 h-px w-full border-0 bg-gray-600' />

      <Text color='gray' size='xs' weight='medium'>
        {currencySymbol
          ? `${currencySymbol} fees don&apos;t allow for accurate exact outputs. Use the 'You pay' field instead.`
          : 'Fees on the selected output token don&apos;t allow for accurate exact outputs. Use the `You pay` field instead.'}
      </Text>
    </>
  );
}
