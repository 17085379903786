import jazzicon from '@metamask/jazzicon';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { cn } from 'utils/cn';

import useENSAvatar from 'hooks/useENSAvatar';

export const enum IdenticonSize {
  Small = 'size-6',
  Medium = 'size-9'
}

const getSizeFromEnum = (size: IdenticonSize) => {
  switch (size) {
    case IdenticonSize.Small:
      return 24;
    case IdenticonSize.Medium:
    default:
      return 40;
  }
};

const Identicon = ({
  account,
  size = IdenticonSize.Small
}: {
  account: string;
  size?: IdenticonSize;
}) => {
  const { avatar } = useENSAvatar(account ?? undefined);
  const [fetchable, setFetchable] = useState(true);
  const iconSize = getSizeFromEnum(size);

  const icon = useMemo(
    () => account && jazzicon(iconSize, parseInt(account.slice(2, 10), 16)),
    [account, iconSize]
  );
  const iconRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const current = iconRef.current;
    if (icon) {
      current?.appendChild(icon);
      return () => {
        try {
          current?.removeChild(icon);
        } catch (e) {
          console.error('Avatar icon not found');
        }
      };
    }
    return;
  }, [icon, iconRef]);

  const handleError = useCallback(() => setFetchable(false), []);

  return (
    <div className={cn('rounded-full bg-gray-100', size)}>
      {avatar && fetchable ? (
        <img alt='avatar' src={avatar} onError={handleError} />
      ) : (
        <span ref={iconRef} />
      )}
    </div>
  );
};

export default Identicon;
