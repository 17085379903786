import {
  type ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import { cn } from 'utils/cn';

import { useClipboard } from 'hooks/useClipboard';

import { CheckIcon, CopyIcon } from 'components/Icons';

interface CopyHelperProps {
  toCopy: string;
  children: ReactNode;
}

type CopyHelperRefType = { forceCopy: () => void };
export const CopyHelper = forwardRef<CopyHelperRefType, CopyHelperProps>(
  ({ toCopy, children }: CopyHelperProps, ref) => {
    const { isCopied, copyToClipboard } = useClipboard();
    const [isHover, setIsHover] = useState(false);

    useImperativeHandle(ref, () => ({
      forceCopy() {
        copyToClipboard(toCopy);
      }
    }));

    const textRef = useRef<HTMLDivElement>(null);
    const isEllipsis = Boolean(
      textRef.current &&
        textRef.current.offsetWidth < textRef.current.scrollWidth
    );

    const showIcon = Boolean(isHover || isCopied);
    const Icon = isCopied ? CheckIcon : showIcon ? CopyIcon : null;

    return (
      <div
        onClick={() => copyToClipboard(toCopy)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={cn(
          'hover-opacity-60 active-opacity-40 flex cursor-pointer flex-row items-center duration-300',
          {
            'gap-1': !isEllipsis
          }
        )}
      >
        <div
          ref={textRef}
          className='overflow-hidden overflow-ellipsis whitespace-nowrap'
        >
          {children}
        </div>

        {Icon && (
          <Icon
            className={cn({
              'text-green': isCopied
            })}
          />
        )}
      </div>
    );
  }
);
CopyHelper.displayName = 'CopyHelper';
