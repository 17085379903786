import { SwapRoute } from './SwapRoute';

import { isClassicTrade } from 'state/routing/utils';
import { useFormatter } from 'utils/formatNumbers';

import RouterLabel from 'components/RouterLabel';

import type { ClassicTrade, SubmittableTrade } from 'state/routing/types';

const RouteLabel = ({ trade }: { trade: SubmittableTrade }) => (
  <div className='flex items-center justify-between p-0'>
    <span className='text-gray-600'>Order Routing</span>

    <RouterLabel trade={trade} />
  </div>
);

const PriceImpactRow = ({ trade }: { trade: ClassicTrade }) => {
  const { formatPercent } = useFormatter();

  return (
    <span className='text-gray-600'>
      <div className='flex items-center justify-between p-0'>
        Price Impact
        <div>{formatPercent(trade.priceImpact)}</div>
      </div>
    </span>
  );
};

export const RoutingTooltip = ({ trade }: { trade: SubmittableTrade }) =>
  isClassicTrade(trade) ? (
    <div className='flex flex-col justify-start gap-2'>
      <PriceImpactRow trade={trade} />
      <div className='h-px w-full bg-gray-800' />
      <RouteLabel trade={trade} />
      <SwapRoute trade={trade} />
    </div>
  ) : (
    <div className='flex flex-col justify-start gap-2'>
      <RouteLabel trade={trade} />
      <div className='h-px w-full bg-gray-800' />
    </div>
  );
