import { ChainId, type Currency } from '@uniswap/sdk-core';
import blankTokenUrl from 'assets/svg/blank_token.svg';
import React from 'react';
import { Loader } from 'react-feather';

import { cn } from 'utils/cn';

import useTokenLogoSource from 'hooks/useAssetLogoSource';
import useENSAvatar from 'hooks/useENSAvatar';

import { ContractIcon } from 'components/Icons';
import { IdenticonSize } from 'components/Identicon';
import { AssetLogoSize } from 'components/Logo/AssetLogo';
import { ChainLogo } from 'components/Logo/ChainLogo';
import { Unicon } from 'components/Unicon';

export enum PortfolioLogoSize {
  xs = 'size-4',
  sm = 'size-6',
  md = 'size-8',
  lg = 'size-9',
  xl = 'size-10'
}

const getHalfWidth = (size: PortfolioLogoSize | string) => {
  if (size === PortfolioLogoSize.xs) {
    return 'w-2';
  }

  if (size === PortfolioLogoSize.sm) {
    return 'w-3';
  }

  if (size === PortfolioLogoSize.md) {
    return 'w-4';
  }

  if (size === PortfolioLogoSize.lg) {
    return 'w-4.5';
  }

  if (size === PortfolioLogoSize.xl) {
    return 'w-5';
  }

  return size;
};

type DoubleLogoProps = {
  logo1?: string;
  logo2?: string;
  size: PortfolioLogoSize | string;
  onError1?: () => void;
  onError2?: () => void;
};

const DoubleLogo = ({
  logo1,
  onError1,
  logo2,
  onError2,
  size
}: DoubleLogoProps) => {
  const halfWidth = getHalfWidth(size);

  return (
    <div className={cn('relative left-0 top-0 flex flex-row')}>
      <img
        className={cn('object-cover object-left', size, halfWidth)}
        src={logo1 ?? blankTokenUrl}
        onError={onError1}
      />
      <img
        className={cn('object-cover object-right', size, halfWidth)}
        src={logo2 ?? blankTokenUrl}
        onError={onError2}
      />
    </div>
  );
};

type DoubleCurrencyLogoProps = {
  chainId: ChainId;
  currencies: Array<Currency | undefined>;
  backupImages?: Array<string | undefined>;
  size: PortfolioLogoSize | string;
};

const DoubleCurrencyLogo = ({
  chainId,
  currencies,
  backupImages,
  size
}: DoubleCurrencyLogoProps) => {
  const [src, nextSrc] = useTokenLogoSource(
    currencies?.[0]?.wrapped.address,
    chainId,
    currencies?.[0]?.isNative,
    backupImages?.[0]
  );
  const [src2, nextSrc2] = useTokenLogoSource(
    currencies?.[1]?.wrapped.address,
    chainId,
    currencies?.[1]?.isNative,
    backupImages?.[1]
  );

  if (currencies.length === 1 && src) {
    return (
      <img className={cn('rounded-full', size)} src={src} onError={nextSrc} />
    );
  }
  if (currencies.length > 1) {
    return (
      <DoubleLogo
        logo1={src}
        onError1={nextSrc}
        logo2={src2}
        onError2={nextSrc2}
        size={size}
      />
    );
  }
  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-full bg-blue-800/20 text-center font-medium text-gray-100',
        size,
        {
          'text-5xs leading-3': size === AssetLogoSize.xs,
          'text-3xs leading-6': size === AssetLogoSize.lg
        }
      )}
    >
      {currencies[0]?.symbol
        ?.toUpperCase()
        .replace('$', '')
        .replace(/\s+/g, '')
        .slice(0, 3)}
    </div>
  );
};

type PortfolioAvatarProps = {
  accountAddress: string;
  size: PortfolioLogoSize | string;
};

const PortfolioAvatar = ({ accountAddress, size }: PortfolioAvatarProps) => {
  const { avatar, loading } = useENSAvatar(accountAddress);

  if (loading) {
    return <Loader size={size} />;
  }
  if (avatar) {
    return <img src={avatar} alt='avatar' className='size-10 rounded-lg' />;
  }
  return <Unicon size={IdenticonSize.Medium} address={accountAddress} />;
};

const SquareL2Logo = ({
  chainId,
  size
}: {
  chainId: ChainId;
  size?: number;
}) => {
  if (chainId === ChainId.MAINNET) return null;

  return (
    <div
      className='absolute -bottom-1 left-0 flex size-5 items-center justify-center rounded-full bg-blue-900'
      style={{ width: `${size || 20}px`, height: `${size || 20}px` }}
    >
      <ChainLogo chainId={chainId} ignoreUnsupported size={size ?? 16} />
    </div>
  );
};

type getLogoProps = {
  size: PortfolioLogoSize | string;
  chainId: ChainId;
  accountAddress?: string;
  currencies?: Array<Currency | undefined>;
  images?: Array<string | undefined>;
};

const getLogo = ({
  chainId,
  accountAddress,
  currencies,
  images,
  size
}: getLogoProps) => {
  if (accountAddress) {
    return <PortfolioAvatar accountAddress={accountAddress} size={size} />;
  }
  if (currencies && currencies.length) {
    return (
      <DoubleCurrencyLogo
        chainId={chainId}
        currencies={currencies}
        backupImages={images}
        size={size}
      />
    );
  }
  if (images?.length === 1) {
    return (
      <img
        className={cn('rounded-full', size)}
        src={images[0] ?? blankTokenUrl}
      />
    );
  }
  if (images && images?.length >= 2) {
    return (
      <DoubleLogo
        logo1={images[0]}
        logo2={images[images.length - 1]}
        size={size}
      />
    );
  }
  return <ContractIcon className={cn(size, 'text-gray-600')} />;
};

type PortfolioLogoProps = {
  chainId: ChainId;
  accountAddress?: string;
  currencies?: Array<Currency | undefined>;
  images?: Array<string | undefined>;
  subSize?: number;
  size?: PortfolioLogoSize | string;
  style?: React.CSSProperties;
};

export const PortfolioLogo = ({
  chainId,
  accountAddress,
  currencies,
  images,
  style,
  subSize,
  size = PortfolioLogoSize.xl
}: PortfolioLogoProps) => (
  <div className='relative left-0 top-0 flex items-center' style={style}>
    {getLogo({ size, chainId, accountAddress, currencies, images })}
    <SquareL2Logo chainId={chainId} size={subSize} />
  </div>
);
