import { useEffect, useState } from 'react';

import { cn } from 'utils/cn';

interface TransitionTextProps {
  initialText: React.ReactNode;
  transitionText: React.ReactNode;
  transitionTimeMs?: number;
  onTransition?: () => void;
}

export const TransitionText = ({
  initialText,
  transitionText,
  transitionTimeMs = 1500,
  onTransition
}: TransitionTextProps) => {
  const [transitioned, setTransitioned] = useState(false);
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    // Transition from initial text to transition text.
    const timeout = setTimeout(() => {
      if (!transitioned) {
        setExiting(true);

        setTimeout(() => {
          setTransitioned(true);
          setExiting(false);
          onTransition?.();
        }, 300);
      }
    }, transitionTimeMs);

    return () => clearTimeout(timeout);
  }, [onTransition, transitionTimeMs, transitioned]);

  return (
    <div className='relative min-h-8 w-full'>
      {!transitioned && (
        <div
          className={cn('absolute h-full w-full', {
            'animate-slide-in': !exiting,
            'animate-slide-out': exiting
          })}
        >
          {initialText}
        </div>
      )}
      {transitioned && (
        <div
          className={cn('animate-slide-in absolute h-full w-full', {
            'animate-slide-in': !exiting,
            'animate-slide-out': exiting
          })}
        >
          {transitionText}
        </div>
      )}
    </div>
  );
};
