import sockImg from '../../assets/svg/socks.svg';
import type { Connection } from 'connection/types';

import { cn } from 'utils/cn';
import { getWalletMeta } from 'utils/walletMeta';

import { useHasSocks } from '../../hooks/useSocksBalance';
import useENSAvatar from 'hooks/useENSAvatar';
import { useWeb3React } from 'hooks/useWeb3React';

import Identicon, { IdenticonSize } from 'components/Identicon';
import { Unicon } from 'components/Unicon';

type MiniIconContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const MiniIconContainer = ({ children, className }: MiniIconContainerProps) => (
  <div
    className={cn(
      'outline-bg-blue-900 absolute -bottom-1 flex size-4 items-center justify-center overflow-clip rounded-full bg-blue-900 outline outline-2',
      className
    )}
  >
    {children}
  </div>
);

const Socks = () => (
  <MiniIconContainer className='-left-1'>
    <img src={sockImg} className='size-4' />
  </MiniIconContainer>
);

const MiniWalletIcon = ({
  connection,
  className
}: {
  connection: Connection;
  className?: string;
}) => {
  const { provider } = useWeb3React();

  const providerInfo = connection.getProviderInfo();

  // Uses icon from wallet meta when available, otherwise uses icon from connection
  const icon =
    (provider && getWalletMeta(provider)?.icons?.[0]) ?? providerInfo.icon;

  return (
    <MiniIconContainer className={className}>
      <img src={icon} alt={`${providerInfo.name} icon`} className='size-4' />
    </MiniIconContainer>
  );
};

const MainWalletIcon = ({
  account,
  connection,
  size
}: {
  account: string;
  connection: Connection;
  size: IdenticonSize;
}) => {
  const { avatar } = useENSAvatar(account ?? undefined);

  if (!account) return null;

  const hasIdenticon =
    avatar || connection.getProviderInfo().name === 'MetaMask';

  return hasIdenticon ? (
    <Identicon account={account} size={size} />
  ) : (
    <Unicon address={account} size={size} />
  );
};

const StatusIcon = ({
  account,
  connection,
  size,
  showMiniIcons = true
}: {
  account: string;
  connection: Connection;
  size?: IdenticonSize;
  showMiniIcons?: boolean;
}) => {
  const hasSocks = useHasSocks();

  return (
    <div
      className={cn(
        'relative mr-1 flex flex-col flex-nowrap items-end justify-center lg:items-center'
      )}
    >
      <MainWalletIcon
        account={account}
        connection={connection}
        size={size || IdenticonSize.Small}
      />
      {showMiniIcons && (
        <MiniWalletIcon connection={connection} className='-right-1' />
      )}
      {hasSocks && showMiniIcons && <Socks />}
    </div>
  );
};

export default StatusIcon;
