import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from 'utils/cn';

const badgeVariants = cva(
  'inline-flex items-center rounded p-1 text-xs font-medium transition-colors duration-300',
  {
    variants: {
      variant: {
        default: 'text-blue-200 bg-blue-200/15',
        'dark-blue': 'text-white bg-blue-800',
        blue: 'text-blue-800 bg-blue-200',
        yellow: 'text-white bg-yellow',
        green: 'text-green bg-green/30',
        red: 'text-white bg-red'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

type BadgePropsOriginal = {
  className?: string;
  children: React.ReactNode;
};

interface BadgeProps
  extends BadgePropsOriginal,
    VariantProps<typeof badgeVariants> {}

const Badge = ({ className, variant, ...props }: BadgeProps) => (
  <div className={cn(badgeVariants({ variant }), className)} {...props} />
);

export { Badge };
