import type { Currency } from '@uniswap/sdk-core';
import type { FeeAmount } from '@uniswap/v3-sdk';
import { ReactComponent as DotLine } from 'assets/svg/dot_line.svg';
import { BIPS_BASE } from 'constants/misc';

import type { RoutingDiagramEntry } from 'utils/getRoutingDiagramEntries';

import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList';

import { Badge } from 'components/ui/Badge';
import { PrimaryText } from 'components/ui/Text';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

import DoubleCurrencyLogo from 'components/DoubleLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';

const RoutingDiagram = ({
  currencyIn,
  currencyOut,
  routes
}: {
  currencyIn: Currency;
  currencyOut: Currency;
  routes: RoutingDiagramEntry[];
}) => {
  const tokenIn = useTokenInfoFromActiveList(currencyIn);
  const tokenOut = useTokenInfoFromActiveList(currencyOut);

  return (
    <div className='flex w-full items-center'>
      {routes.map((entry, index) => (
        <div
          className='flex w-full flex-row items-center justify-start'
          key={index}
        >
          <CurrencyLogo currency={tokenIn} />

          <Route entry={entry} />

          <CurrencyLogo currency={tokenOut} />
        </div>
      ))}
    </div>
  );
};

export default RoutingDiagram;

const Route = ({
  entry: { percent, path }
}: {
  entry: RoutingDiagramEntry;
}) => (
  <div className='relative flex w-full items-center justify-center gap-2 px-2 py-0.5'>
    <div className='absolute z-1 flex w-full items-center opacity-50'>
      <DotLine />
    </div>

    <Badge
      variant='blue'
      className='z-sticky flex justify-start gap-1 rounded-lg'
    >
      <PrimaryText color='dark-blue' className='text-nowrap'>
        {percent.toSignificant(2)}%
      </PrimaryText>
    </Badge>

    <div className='flex w-full flex-wrap items-center justify-between'>
      {path.map(([currency0, currency1, feeAmount], index) => (
        <Pool
          key={index}
          currency0={currency0}
          currency1={currency1}
          feeAmount={feeAmount}
        />
      ))}
    </div>
  </div>
);

const Pool = ({
  currency0,
  currency1,
  feeAmount
}: {
  currency0: Currency;
  currency1: Currency;
  feeAmount: FeeAmount;
}) => {
  const tokenInfo0 = useTokenInfoFromActiveList(currency0);
  const tokenInfo1 = useTokenInfoFromActiveList(currency1);

  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger className='cursor-default' asChild>
        <div className='cursor-default font-bold text-gray-700/50'>
          <Badge
            variant='blue'
            className='relative z-sticky flex flex-row gap-2'
          >
            <DoubleCurrencyLogo currency0={tokenInfo1} currency1={tokenInfo0} />

            <PrimaryText color='dark-blue' className='text-nowrap'>
              {feeAmount / BIPS_BASE}%
            </PrimaryText>
          </Badge>
        </div>
      </TooltipTrigger>
      <TooltipContent className='max-w-50 bg-blue-700' side='bottom'>
        {`${tokenInfo0?.symbol + '/' + tokenInfo1?.symbol + ' ' + feeAmount / 10000}% pool`}
      </TooltipContent>
    </Tooltip>
  );
};
