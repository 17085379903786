import type { Currency } from '@uniswap/sdk-core';
import { ReactComponent as PapersIcon } from 'assets/svg/papers-text.svg';

import { cn } from 'utils/cn';

import {
  TransactionFailedIcon,
  TransactionSubmittedIcon
} from 'components/Icons';
import Spinner from 'components/Icons/Spinner';
import { AssetLogoSize } from 'components/Logo/AssetLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';

export const PaperIcon = () => (
  <div className='absolute z-1 flex h-full w-full items-center justify-center rounded-full bg-blue-200'>
    <PapersIcon />
  </div>
);

export const CurrencyLoader = ({
  currency,
  asBadge = false
}: {
  currency?: Currency;
  asBadge?: boolean;
}) => (
  <div
    className={cn(
      'rounded-1/2 z-2 absolute transition-all duration-300 ease-in-out',
      {
        '-bottom-1 -right-1 size-5 outline outline-2 outline-gray-800': asBadge,
        'bottom-0 right-0 h-full w-full': !asBadge
      }
    )}
  >
    <CurrencyLogo
      size={asBadge ? AssetLogoSize.md : AssetLogoSize.xxxl}
      currency={currency}
      className='z-1'
    />
  </div>
);

export const ConfirmedIcon = () => (
  <TransactionSubmittedIcon className='size-16' />
);

export const SubmittedIcon = () => (
  <TransactionSubmittedIcon className='size-16' />
);

export const ErrorIcon = () => <TransactionFailedIcon className='size-16' />;

export const LoadingIndicatorOverlay = () => (
  <Spinner className='size-16 stroke-blue-200' />
);
