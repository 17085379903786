import { useClipboard } from 'hooks/useClipboard';

import { SecondaryText } from 'components/ui/Text';

export const GitVersionRow = () => {
  const { copyToClipboard } = useClipboard();

  return process.env.REACT_APP_GIT_COMMIT_HASH ? (
    <div
      className='w-full cursor-pointer'
      onClick={() => {
        copyToClipboard(process.env.REACT_APP_GIT_COMMIT_HASH as string);
      }}
    >
      <SecondaryText>
        {`Version: ${process.env.REACT_APP_GIT_COMMIT_HASH.substring(0, 6)}`}
      </SecondaryText>
    </div>
  ) : null;
};
