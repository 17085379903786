import { DialogContent, DialogOverlay } from '@reach/dialog';
import React from 'react';
import { animated, easings, useSpring, useTransition } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import { cn } from 'utils/cn';
import { isMobile } from 'utils/wallet';

import Button from 'components/ui/Button';

import {
  InnerInwardDesign,
  OuterInwardDesign
} from 'components/composed/ContainerShapes';
import { ContainerSpaceWidth } from 'components/composed/ContainerShapes/constants';

export const MODAL_TRANSITION_DURATION = 200;

const AnimatedDialogOverlay = animated(DialogOverlay);

const AnimatedDialogContent = animated(DialogContent);

interface ModalProps {
  isOpen: boolean;
  onDismiss?: () => void;
  children?: React.ReactNode;
  $scrollOverlay?: boolean;
  closeButton?: boolean;
  className?: string;
}

const Modal = ({
  isOpen,
  onDismiss,
  children,
  $scrollOverlay,
  closeButton = false,
  className
}: ModalProps) => {
  const fadeTransition = useTransition(isOpen, {
    config: { duration: MODAL_TRANSITION_DURATION },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const slideTransition = useTransition(isOpen, {
    config: {
      duration: MODAL_TRANSITION_DURATION,
      easing: easings.easeInOutCubic
    },
    from: { transform: 'translateY(40px)' },
    enter: { transform: 'translateY(0px)' },
    leave: { transform: 'translateY(40px)' }
  });

  const [{ y }, set] = useSpring(() => ({
    y: 0,
    config: { mass: 1, tension: 210, friction: 20 }
  }));
  const bind = useGesture({
    onDrag: (state) => {
      set({
        y: state.down ? state.movement[1] : 0
      });
    }
  });

  return (
    <>
      {fadeTransition(
        ({ opacity }, item) =>
          item && (
            <AnimatedDialogOverlay
              className={cn(
                'bg-blue-900',
                'z-backdrop flex items-end justify-center overflow-hidden bg-blue-900/40 lg:items-center',
                {
                  'overflow-y-scroll': $scrollOverlay
                }
              )}
              style={{
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })
              }}
              onDismiss={onDismiss}
            >
              {slideTransition(
                (styles, item) =>
                  item && (
                    <AnimatedDialogContent
                      className={cn(
                        'm-0 flex flex-col bg-blue-900 bg-transparent p-0 lg:m-auto',
                        'max-h-256 w-full lg:max-w-105',
                        'overflow-y-auto overflow-x-hidden',
                        'lg:rounded-logo',
                        {
                          'inline-table': $scrollOverlay
                        },
                        className
                      )}
                      {...(isMobile
                        ? {
                            ...bind(),
                            style: {
                              transform: y.interpolate(
                                (y) =>
                                  `translateY(${(y as number) > 0 ? y : 0}px)`
                              )
                            }
                          }
                        : {})}
                      aria-label='dialog'
                    >
                      <div className='relative flex flex-col'>
                        <OuterInwardDesign
                          size={ContainerSpaceWidth.OUTER_MEDIUM}
                        />

                        <InnerInwardDesign
                          size={ContainerSpaceWidth.INNER_MEDIUM}
                          className='absolute left-0 top-0 w-full p-1.5'
                        />
                      </div>

                      {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                      {isMobile ? <div tabIndex={1} /> : null}

                      <div className='flex flex-grow flex-col bg-blue-800 lg:rounded-b-2xl'>
                        <div
                          className={cn(
                            'm-1.5 flex flex-grow flex-col bg-blue-900',
                            {
                              'lg:rounded-b-2xl': !closeButton
                            }
                          )}
                        >
                          {children}
                        </div>
                      </div>

                      {closeButton && (
                        <div className='relative mb-6.75 flex flex-col'>
                          <OuterInwardDesign
                            size={ContainerSpaceWidth.OUTER_MEDIUM}
                            className='rotate-180'
                          />

                          <InnerInwardDesign
                            size={ContainerSpaceWidth.INNER_MEDIUM}
                            className='absolute bottom-0 left-0 w-full rotate-180 p-1.5'
                          />

                          <Button
                            onClick={onDismiss}
                            className='absolute -bottom-5.75 left-1/2 z-11 w-41.25 -translate-x-1/2'
                          >
                            Close
                          </Button>
                        </div>
                      )}
                    </AnimatedDialogContent>
                  )
              )}
            </AnimatedDialogOverlay>
          )
      )}
    </>
  );
};

export default Modal;
