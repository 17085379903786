import StatusIcon from '../Identicon/StatusIcon';
import { useCachedPortfolioBalancesQuery } from '../PrefetchBalancesWrapper/PrefetchBalancesWrapper';
import { CopyHelper } from './CopyHelper';
import { IconButton } from './IconButton';
import MiniPortfolio from './MiniPortfolio';
import { getConnection } from 'connection';
import { useCallback, useState } from 'react';

import { shortenAddress } from 'utils';
import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import useENSName from 'hooks/useENSName';
import { useWeb3React } from 'hooks/useWeb3React';
import { useAppDispatch } from 'state/hooks';

import {
  Primary4XLText,
  PrimaryLargeText,
  SecondaryLargeText,
  SecondaryText
} from 'components/ui/Text';

import { Power } from 'components/Icons/Power';
import { SettingsIcon } from 'components/Icons/SettingsIcon';
import { IdenticonSize } from 'components/Identicon';
import { DeltaArrow } from 'components/Tokens/TokenDetails/Delta';
import { LoadingBubble } from 'components/composed/LoadingBubble';

import { setRecentConnectionDisconnected } from 'state/user/reducer';

const AuthenticatedHeader = ({
  account,
  openSettings
}: {
  account: string;
  openSettings: () => void;
}) => {
  const { connector } = useWeb3React();
  const { ENSName } = useENSName(account);
  const dispatch = useAppDispatch();

  const { formatNumber, formatDelta } = useFormatter();

  const connection = getConnection(connector);
  const disconnect = useCallback(() => {
    connector.deactivate?.();
    connector.resetState();
    dispatch(setRecentConnectionDisconnected());
  }, [connector, dispatch]);

  const { data: portfolioBalances } = useCachedPortfolioBalancesQuery({
    account
  });
  const portfolio = portfolioBalances?.portfolios?.[0];
  const totalBalance = portfolio?.tokensTotalDenominatedValue?.value;
  const absoluteChange =
    portfolio?.tokensTotalDenominatedValueChange?.absolute?.value;
  const percentChange =
    portfolio?.tokensTotalDenominatedValueChange?.percentage?.value;
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);

  return (
    <div className='flex h-full flex-1 flex-col gap-5 px-4 py-5'>
      <div className='flex items-start justify-between gap-1'>
        <div className='flex flex-row gap-1'>
          <StatusIcon
            account={account}
            connection={connection}
            size={IdenticonSize.Medium}
          />

          {account && (
            <div
              className={cn(
                'flex w-full flex-col justify-center overflow-hidden text-nowrap',
                {
                  hidden: showDisconnectConfirm
                }
              )}
            >
              <PrimaryLargeText>
                <CopyHelper toCopy={ENSName ?? account}>
                  {ENSName ?? shortenAddress(account)}
                </CopyHelper>
              </PrimaryLargeText>

              {ENSName && (
                <SecondaryText>
                  <CopyHelper toCopy={account}>
                    {shortenAddress(account)}
                  </CopyHelper>
                </SecondaryText>
              )}
            </div>
          )}
        </div>

        <div className='flex flex-row items-center gap-1'>
          <IconButton
            icon={SettingsIcon}
            onClick={openSettings}
            className={cn({ hidden: showDisconnectConfirm })}
            iconClassName='size-6 stroke-gray-600'
          />

          <IconButton
            icon={Power}
            onClick={disconnect}
            onConfirm={() => setShowDisconnectConfirm(true)}
            onDecline={() => setShowDisconnectConfirm(false)}
            confirmText='Disconnect'
            iconClassName='size-6 stroke-gray-600'
          />
        </div>
      </div>

      <div className='flex flex-1 flex-col justify-start'>
        {totalBalance !== undefined ? (
          <div className='flex animate-fade-in flex-col justify-start gap-8 transition duration-300'>
            <Primary4XLText>
              {formatNumber({
                input: totalBalance,
                type: NumberType.PortfolioBalance
              })}
            </Primary4XLText>

            <div className='flex w-full flex-wrap items-center justify-start'>
              {absoluteChange !== 0 && percentChange && (
                <>
                  <DeltaArrow delta={absoluteChange} />
                  <SecondaryLargeText>
                    {`${formatNumber({
                      input: Math.abs(absoluteChange as number),
                      type: NumberType.PortfolioBalance
                    })} (${formatDelta(percentChange)})`}
                  </SecondaryLargeText>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col justify-start gap-1'>
            <LoadingBubble className='h-11 w-42.5' />
            <LoadingBubble className='mx-0 mb-5 mt-1 h-4 w-full' />
          </div>
        )}

        <MiniPortfolio account={account} />
      </div>
    </div>
  );
};

export default AuthenticatedHeader;
