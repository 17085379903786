import {
  LoadingBubble,
  LoadingBubbleDelay
} from 'components/composed/LoadingBubble';

const PortfolioRow = ({
  left,
  title,
  descriptor,
  right,
  onClick
}: {
  left: React.ReactNode;
  title: React.ReactNode;
  descriptor?: React.ReactNode;
  right?: React.ReactNode;
  setIsHover?: (b: boolean) => void;
  onClick?: () => void;
}) => (
  <div
    className='flex h-17 w-full cursor-pointer items-center justify-start gap-3 px-4 py-0 transition-colors duration-300'
    onClick={onClick}
  >
    {left}
    <div className='grid flex-grow auto-rows-auto'>
      {title}
      {descriptor}
    </div>
    {right && (
      <div className='flex flex-col items-end justify-start'>{right}</div>
    )}
  </div>
);

export default PortfolioRow;

const PortfolioSkeletonRow = ({ shrinkRight }: { shrinkRight?: boolean }) => (
  <div className='flex h-17 w-full items-center justify-start gap-3 px-4 py-0 transition-colors duration-300'>
    <LoadingBubble className='size-10' round />

    <div className='grid flex-grow auto-rows-auto gap-1'>
      <LoadingBubble className='h-4 w-15' delay={LoadingBubbleDelay.Short} />
      <LoadingBubble
        className='w-22.5 h-2.5'
        delay={LoadingBubbleDelay.Short}
      />
    </div>

    <div className='flex flex-col items-end justify-start gap-1'>
      {shrinkRight ? (
        <LoadingBubble className='h-3 w-5' delay={LoadingBubbleDelay.Medium} />
      ) : (
        <>
          <LoadingBubble
            className='w-17.5 h-3.5'
            delay={LoadingBubbleDelay.Medium}
          />
          <LoadingBubble
            className='h-3.5 w-12.5'
            delay={LoadingBubbleDelay.Medium}
          />
        </>
      )}
    </div>
  </div>
);

export const PortfolioSkeleton = ({
  shrinkRight = false
}: {
  shrinkRight?: boolean;
}) => (
  <>
    {Array.from({ length: 5 }).map((_, i) => (
      <PortfolioSkeletonRow
        shrinkRight={shrinkRight}
        key={`portfolio loading row${i}`}
      />
    ))}
  </>
);
