import { SecondaryText } from 'components/ui/Text';

import type { SubmittableTrade } from 'state/routing/types';
import { QuoteMethod } from 'state/routing/types';

export default function RouterLabel({ trade }: { trade: SubmittableTrade }) {
  if (trade.quoteMethod === QuoteMethod.CLIENT_SIDE_FALLBACK) {
    return <SecondaryText>Client</SecondaryText>;
  }

  return <SecondaryText>API</SecondaryText>;
}
