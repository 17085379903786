import type { Token } from '@uniswap/sdk-core';
import {
  type Warning,
  checkWarning,
  displayWarningLabel,
  getWarningCopy
} from 'constants/tokenSafety';
import { type PropsWithChildren } from 'react';

import { cn } from 'utils/cn';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink';

import { useToken } from 'hooks/Tokens';
import { useClipboard } from 'hooks/useClipboard';
import { useAddUserToken } from 'state/user/hooks';

import Button from 'components/ui/Button';
import { PrimaryLargeText, PrimaryText } from 'components/ui/Text';

import { CopyIcon } from 'components/Icons';
import ExternalLinkIcon from 'components/Icons/ExternalLinkIcon';
import AssetLogo, { AssetLogoSize } from 'components/Logo/AssetLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';
import TokenSafetyLabel from 'components/TokenSafety/TokenSafetyLabel';

const Buttons = ({
  warning,
  onContinue,
  onCancel,
  onBlocked,
  showCancel
}: {
  warning?: Warning;
  onContinue?: () => void;
  onCancel: () => void;
  onBlocked?: () => void;
  showCancel?: boolean;
}) => {
  if (!warning) {
    return (
      <>
        <Button variant='secondary' onClick={onContinue}>
          I understand
        </Button>

        {showCancel && (
          <Button variant='primary-light' onClick={onCancel}>
            Cancel
          </Button>
        )}
      </>
    );
  }

  if (warning.canProceed) {
    return (
      <>
        <Button variant='secondary' onClick={onContinue}>
          {!displayWarningLabel(warning) ? 'Continue' : 'I understand'}
        </Button>

        {showCancel && (
          <Button variant='primary-light' onClick={onCancel}>
            Cancel
          </Button>
        )}
      </>
    );
  }

  return (
    <Button variant='primary-light' onClick={onBlocked ?? onCancel}>
      Close
    </Button>
  );
};

const SafetyLabel = ({ warning }: { warning: Warning }) => (
  <TokenSafetyLabel level={warning.level} canProceed={warning.canProceed}>
    {warning.message}
  </TokenSafetyLabel>
);

const Tooltip = () => (
  <div
    className={cn(
      'flex flex-col items-center justify-center rounded-lg bg-blue-200/40 p-2 text-center text-xs text-blue-200',
      'z-tooltip translate-x-1.25 absolute translate-y-8'
    )}
  >
    Copied!
  </div>
);

const CopyToClipboard = ({
  toCopy,
  children
}: PropsWithChildren<{ toCopy: string }>) => {
  const { isCopied, copyToClipboard } = useClipboard();

  return (
    <div
      className='flex cursor-pointer flex-row items-center justify-center'
      onClick={() => copyToClipboard(toCopy)}
    >
      {children}
      {isCopied && <Tooltip />}
    </div>
  );
};

const CopyLinkIcon = ({ toCopy }: { toCopy: string }) => (
  <CopyToClipboard toCopy={toCopy}>
    <CopyIcon className='size-4 text-blue-200 transition-colors duration-300 hover:text-blue-300' />
  </CopyToClipboard>
);

const ExplorerView = ({ token }: { token: Token }) => {
  if (token) {
    const explorerLink = getExplorerLink({
      chainId: token?.chainId,
      data: token?.address,
      type: ExplorerDataType.TOKEN
    });

    return (
      <div className='mt-2.5 flex h-8 w-full items-center gap-1 overflow-hidden rounded-lg bg-blue-200/40 px-3 py-0.5 text-lg'>
        <div
          className='group flex cursor-pointer items-center overflow-hidden text-blue-200 transition-colors duration-300 hover:text-blue-300'
          onClick={() => window.open(explorerLink, '_blank')}
        >
          <span className='block overflow-hidden text-ellipsis whitespace-nowrap text-sm'>
            {explorerLink}
          </span>

          <div className='flex cursor-pointer flex-row items-center justify-center'>
            <ExternalLinkIcon className='size-4 stroke-blue-200 transition-colors duration-300 group-hover:stroke-blue-300' />
          </div>
        </div>

        <CopyLinkIcon toCopy={explorerLink} />
      </div>
    );
  } else {
    return null;
  }
};

export interface TokenSafetyProps {
  tokenAddress: string | null;
  secondTokenAddress?: string;
  onContinue: () => void;
  onCancel: () => void;
  onBlocked?: () => void;
  showCancel?: boolean;
}

const TokenSafety = ({
  tokenAddress,
  secondTokenAddress,
  onContinue,
  onCancel,
  onBlocked,
  showCancel
}: TokenSafetyProps) => {
  const logos = [];
  const urls = [];

  const token1Warning = tokenAddress ? checkWarning(tokenAddress) : null;
  const token1 = useToken(tokenAddress);
  const token2Warning = secondTokenAddress
    ? checkWarning(secondTokenAddress)
    : null;
  const token2 = useToken(secondTokenAddress);

  const token1Unsupported = Boolean(token1Warning && !token1Warning.canProceed);
  const token2Unsupported = Boolean(token2Warning && !token2Warning.canProceed);

  // Logic for only showing the 'unsupported' warning if one is supported and other isn't
  if (
    token1 &&
    token1Warning &&
    (token1Unsupported || !(token2Warning && token2Unsupported))
  ) {
    logos.push(<CurrencyLogo currency={token1} size={AssetLogoSize.xxl} />);
    urls.push(<ExplorerView token={token1} />);
  }
  if (
    token2 &&
    token2Warning &&
    (token2Unsupported || !(token1Warning && token1Unsupported))
  ) {
    logos.push(<CurrencyLogo currency={token2} size={AssetLogoSize.xxl} />);
    urls.push(<ExplorerView token={token2} />);
  }

  const plural = logos.length > 1;
  // Show higher level warning if two are present
  let displayWarning = token1Warning;
  if (
    !token1Warning ||
    (token2Warning && token2Unsupported && !token1Unsupported)
  ) {
    displayWarning = token2Warning;
  }

  // If a warning is acknowledged, import these tokens
  const addToken = useAddUserToken();
  const acknowledge = () => {
    if (token1) {
      addToken(token1);
    }
    if (token2) {
      addToken(token2);
    }
    onContinue();
  };

  const { heading, description } = getWarningCopy(displayWarning, plural);

  if (displayWarning) {
    return (
      <div className='relative flex w-full flex-col items-center'>
        <div className='flex w-full flex-col items-center gap-6 px-10 py-8'>
          <div className='flex w-full flex-col items-center gap-2'>
            <div className='flex flex-row gap-2'>
              <div className='flex flex-row gap-4'>{logos}</div>
            </div>

            {displayWarningLabel(displayWarning) && (
              <div className='flex flex-row gap-2'>
                <SafetyLabel warning={displayWarning} />
              </div>
            )}

            <div className='flex flex-row gap-2'>
              <PrimaryText className='text-center'>
                {heading} {description}
              </PrimaryText>
            </div>

            <div className='relative flex w-full flex-col gap-2'>{urls}</div>
          </div>

          <div className='flex flex-row gap-2'>
            <Buttons
              warning={displayWarning}
              onContinue={acknowledge}
              onCancel={onCancel}
              onBlocked={onBlocked}
              showCancel={showCancel}
            />
          </div>
        </div>
      </div>
    );
  }

  if (token1 || token2) {
    return (
      <div className='relative flex w-full flex-col items-center'>
        <div className='flex w-full flex-col items-center gap-6 p-6 text-center'>
          <div className='flex w-full flex-col items-center justify-center gap-2'>
            <PrimaryLargeText>
              To continue, please acknowledge that the following tokens are
              loaded from URL:
            </PrimaryLargeText>

            {token1 && (
              <div className='flex flex-row items-center gap-2'>
                <AssetLogo address={token1.address} />

                <PrimaryText>{token1.symbol}</PrimaryText>
              </div>
            )}

            {token2 && (
              <div className='flex flex-row items-center gap-2'>
                <AssetLogo address={token2.address} />

                <PrimaryText>{token2.symbol}</PrimaryText>
              </div>
            )}
          </div>

          <div className='flex flex-row gap-2'>
            <Buttons
              onContinue={acknowledge}
              onCancel={onCancel}
              showCancel={showCancel}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default TokenSafety;
