import { CurrencyItem } from './CurrencyItem';
import { CurrencyList } from './CurrencyList';
import type { ExtendedCurrency } from './types';
import type { Currency, NativeCurrency, Token } from '@uniswap/sdk-core';
import {
  type ChangeEvent,
  type KeyboardEvent,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { UserAddedToken } from 'types/tokens';

import { isAddress } from '../../utils';
import { cn } from 'utils/cn';

import { useDefaultActiveTokens, useToken } from '../../hooks/Tokens';
import useDebounce from 'hooks/useDebounce';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import useToggle from 'hooks/useToggle';
import { useTokenBalances } from 'hooks/useTokenBalances';
import { useWeb3React } from 'hooks/useWeb3React';
import useCurrencyBalance, {
  useCurrencyBalances
} from 'lib/hooks/useCurrencyBalance';
import useNativeCurrency from 'lib/hooks/useNativeCurrency';
import { getTokenFilter } from 'lib/hooks/useTokenList/filtering';
import {
  getSortedPortfolioTokens,
  useSortTokensByQuery
} from 'lib/hooks/useTokenList/sorting';

import SearchInput from 'components/ui/SearchInput';

import { CloseIcon } from 'components/Icons';

type AssetType = NativeCurrency | Token;

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void;
  otherSelectedCurrency?: Currency | null;
  disableNonToken?: boolean;
  onlyShowCurrenciesWithBalance?: boolean;
}

export const CurrencySearch = ({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  disableNonToken,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance
}: CurrencySearchProps) => {
  const { account, chainId } = useWeb3React();

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedQuery = useDebounce(searchQuery, 200);
  const searchToken = useToken(debouncedQuery);

  const defaultTokens = useDefaultActiveTokens(chainId);

  const searchTokenBalance = useCurrencyBalance(account, searchToken?.wrapped);
  const searchTokenWithBalance: ExtendedCurrency | undefined = useMemo(() => {
    if (!searchToken) return undefined;

    return {
      token: searchToken?.wrapped,
      balance: searchTokenBalance
    };
  }, [searchToken, searchTokenBalance]);

  const {
    balanceMap,
    balanceList,
    loading: balancesAreLoading
  } = useTokenBalances();

  const nativeCurrency = useNativeCurrency(chainId);

  const defaultTokensWithNativeCurrency = useMemo(() => {
    if (!nativeCurrency) {
      return defaultTokens;
    }

    return {
      ...defaultTokens,
      [nativeCurrency?.wrapped.address]: nativeCurrency
    };
  }, [defaultTokens, nativeCurrency]);

  const sortedTokens: AssetType[] = useMemo(() => {
    const filteredListTokens = Object.values(defaultTokensWithNativeCurrency)
      .filter(getTokenFilter(debouncedQuery))
      // Filter out tokens with balances so they aren't duplicated when we merge below.
      .filter((asset) => {
        if (asset.isNative) {
          return !(asset.wrapped.address?.toLowerCase() in balanceMap);
        }

        return !(asset.address?.toLowerCase() in balanceMap);
      });

    if (balancesAreLoading) {
      return filteredListTokens;
    }

    const portfolioTokens = getSortedPortfolioTokens(
      balanceList ?? [],
      balanceMap,
      chainId
    );
    const mergedTokens = [...(portfolioTokens ?? []), ...filteredListTokens];

    return mergedTokens.filter((asset) => {
      if (onlyShowCurrenciesWithBalance) {
        const address = asset.isNative ? asset.wrapped.address : asset.address;
        return balanceMap[address?.toLowerCase()]?.usdValue > 0;
      }

      if (asset.isNative && disableNonToken) {
        return false;
      }

      // If there is no query, filter out unselected user-added tokens with no balance.
      if (!debouncedQuery && asset instanceof UserAddedToken) {
        if (
          selectedCurrency?.equals(asset) ||
          otherSelectedCurrency?.equals(asset)
        )
          return true;
        return balanceMap[asset.address.toLowerCase()]?.usdValue > 0;
      }
      return true;
    });
  }, [
    balanceList,
    defaultTokensWithNativeCurrency,
    debouncedQuery,
    balancesAreLoading,
    balanceMap,
    chainId,
    onlyShowCurrenciesWithBalance,
    disableNonToken,
    selectedCurrency,
    otherSelectedCurrency
  ]);

  const isLoading = Boolean(balancesAreLoading && !tokenLoaderTimerElapsed);

  const filteredSortedTokens = useSortTokensByQuery(
    debouncedQuery,
    sortedTokens
  );

  const currencyBalances = useCurrencyBalances(account, filteredSortedTokens);
  const filteredSortedTokensWithBalances: ExtendedCurrency[] = useMemo(
    () =>
      filteredSortedTokens.map((token, index) => ({
        token,
        balance: currencyBalances[index]
      })),
    [filteredSortedTokens, currencyBalances]
  );

  const native = useNativeCurrency(chainId);

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning);
      if (!hasWarning) onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('');
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === native?.symbol?.toLowerCase()) {
          handleCurrencySelect(native);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() ===
              debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [debouncedQuery, native, filteredSortedTokens, handleCurrencySelect]
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true);
    }, 3000);
    return () => clearTimeout(tokenLoaderTimer);
  }, []);

  return (
    <div className='relative flex flex-col gap-4 bg-blue-900 p-4 lg:rounded-b-2xl'>
      <CloseIcon
        className={cn(
          'absolute -top-2.5 right-4 cursor-pointer',
          'stroke-gray-600 hover:stroke-gray-700',
          'transition-colors duration-300'
        )}
        onClick={onDismiss}
      />

      <div className='flex flex-col gap-3.5'>
        <span className='text-center text-base font-medium text-white'>
          Select a token
        </span>

        <SearchInput
          type='text'
          id='token-search-input'
          placeholder='Search name or paste address'
          autoComplete='off'
          value={searchQuery}
          ref={inputRef as RefObject<HTMLInputElement>}
          onChange={handleInput}
          onKeyDown={handleEnter}
        />
      </div>

      <div className='h-px w-full bg-blue-800' />

      {searchTokenWithBalance ? (
        <CurrencyItem
          currency={searchTokenWithBalance}
          onSelect={handleCurrencySelect}
        />
      ) : filteredSortedTokens?.length > 0 || isLoading ? (
        <CurrencyList
          currencies={filteredSortedTokensWithBalances}
          isLoading={isLoading}
          onCurrencySelect={handleCurrencySelect}
        />
      ) : (
        <span className='text-center text-base font-medium text-white'>
          No results found.
        </span>
      )}
    </div>
  );
};
