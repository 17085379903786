import type { Currency, CurrencyAmount } from '@uniswap/sdk-core';
import { useState } from 'react';

import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

import { InfoIcon } from 'components/Icons';
import { AssetLogoSize } from 'components/Logo/AssetLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';

interface SwapModalHeaderAmountProps {
  isLoading: boolean;
  tooltipText?: React.ReactNode;
  label: React.ReactNode;
  amount: CurrencyAmount<Currency>;
  usdAmount?: number;
  currency: Currency;
}

export const SwapModalHeaderAmount = ({
  tooltipText,
  label,
  amount,
  usdAmount,
  currency,
  isLoading
}: SwapModalHeaderAmountProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  const { formatNumber, formatReviewSwapCurrencyAmount } = useFormatter();

  return (
    <div className='flex w-full flex-row items-center justify-between gap-3'>
      <div className='flex flex-col gap-2.5'>
        <div className='flex flex-row items-center gap-1'>
          <span className='text-xs font-medium text-gray-600'>{label}</span>

          {tooltipText && (
            <Tooltip
              delayDuration={0}
              open={isTooltipOpen}
              onOpenChange={setIsTooltipOpen}
            >
              <TooltipTrigger className='cursor-default' asChild>
                <div
                  className='cursor-default font-bold text-gray-700/50'
                  onClick={handleTooltipToggle}
                >
                  <InfoIcon className='rotate-180 stroke-gray-600' />
                </div>
              </TooltipTrigger>
              <TooltipContent className='max-w-80 bg-blue-700' side='bottom'>
                {tooltipText}
              </TooltipContent>
            </Tooltip>
          )}
        </div>

        <div className='flex flex-col gap-2.5'>
          <span
            className={cn('text-5xl font-normal text-gray-100', {
              'text-gray-600': isLoading
            })}
          >
            {formatReviewSwapCurrencyAmount(amount)} {currency?.symbol}
          </span>

          <span className='text-xs font-medium text-gray-600'>
            {formatNumber({
              input: usdAmount,
              type: NumberType.FiatTokenQuantity
            })}
          </span>
        </div>
      </div>

      <CurrencyLogo currency={currency} size={AssetLogoSize.xl} />
    </div>
  );
};
