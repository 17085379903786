import type { ComponentProps } from 'react';

const ExternalLinkIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    {...props}
  >
    <path
      d='M4 2.00021H2C1.44772 2.00021 1 2.44793 1 3.00021V8.00018C1 8.55248 1.44773 9.0002 2.00003 9.00018L7.00003 9.00003C7.5523 9.00001 8 8.5523 8 8.00003V6.00021'
      strokeLinecap='round'
    />
    <path d='M6 1H8.99967V4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4 5.99988L9 0.999878' strokeLinecap='round' />
  </svg>
);

export default ExternalLinkIcon;
