import ChainSelectorRow from './ChainSelectorRow';
import type { ChainId } from '@uniswap/sdk-core';
import { getConnection } from 'connection';
import type { WalletConnectV2 } from 'connection/WalletConnectV2';
import { ConnectionType } from 'connection/types';
import { getChainInfo } from 'constants/chainInfo';
import { NETWORK_SELECTOR_CHAINS, TESTNET_CHAIN_IDS } from 'constants/chains';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useAtomValue } from 'jotai/utils';
import { getChainPriority } from 'utils/chains';
import { cn } from 'utils/cn';
import { getSupportedChainIdsFromWalletConnectSession } from 'utils/getSupportedChainIdsFromWalletConnectSession';

import { useIsMobile } from 'hooks/useIsMobile';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import useSelectChain from 'hooks/useSelectChain';
import useSyncChainQuery from 'hooks/useSyncChainQuery';
import { useWeb3React } from 'hooks/useWeb3React';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

import { showTestnetsAtom } from 'components/AccountDrawer/TestnetsToggle';
import { ChevronIcon } from 'components/Icons';
import { TriangleIcon } from 'components/Icons';
import { ChainLogo } from 'components/Logo/ChainLogo';
import { Portal } from 'components/composed/Portal';

const useWalletSupportedChains = (): ChainId[] => {
  const { connector } = useWeb3React();
  const connectionType = getConnection(connector).type;

  switch (connectionType) {
    case ConnectionType.WALLET_CONNECT_V2:
    case ConnectionType.UNISWAP_WALLET_V2:
      return getSupportedChainIdsFromWalletConnectSession(
        (connector as WalletConnectV2).provider?.session
      );
    default:
      return NETWORK_SELECTOR_CHAINS;
  }
};

const ChainSelector = () => {
  const { chainId } = useWeb3React();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  const showTestnets = useAtomValue(showTestnetsAtom);
  const walletSupportsChain = useWalletSupportedChains();

  const [supportedChains, unsupportedChains] = useMemo(() => {
    const { supported, unsupported } = NETWORK_SELECTOR_CHAINS.filter(
      (chain: number) => showTestnets || !TESTNET_CHAIN_IDS.includes(chain)
    )
      .sort((a, b) => getChainPriority(a) - getChainPriority(b))
      .reduce(
        (acc, chain) => {
          if (walletSupportsChain.includes(chain)) {
            acc.supported.push(chain);
          } else {
            acc.unsupported.push(chain);
          }
          return acc;
        },
        { supported: [], unsupported: [] } as Record<string, ChainId[]>
      );
    return [supported, unsupported];
  }, [showTestnets, walletSupportsChain]);

  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef]);

  const info = getChainInfo(chainId);

  const selectChain = useSelectChain();
  useSyncChainQuery();

  const [pendingChainId, setPendingChainId] = useState<ChainId | undefined>(
    undefined
  );

  const onSelectChain = useCallback(
    async (targetChainId: ChainId) => {
      setPendingChainId(targetChainId);
      await selectChain(targetChainId);
      setPendingChainId(undefined);
      setIsOpen(false);
    },
    [selectChain, setIsOpen]
  );

  if (!chainId) {
    return null;
  }

  const isSupported = !!info;

  const dropdown = (
    <div
      className={cn(
        'z-drawer bg-blue-800 p-1',
        'fixed bottom-0 left-0 right-0 w-full rounded-t-lg',
        'md:absolute md:left-auto md:top-10 md:h-fit md:w-auto md:rounded-lg'
      )}
    >
      {supportedChains.map((selectorChain) => (
        <ChainSelectorRow
          disabled={!walletSupportsChain.includes(selectorChain)}
          onSelectChain={onSelectChain}
          targetChain={selectorChain}
          key={selectorChain}
          isPending={selectorChain === pendingChainId}
        />
      ))}
      {unsupportedChains.map((selectorChain) => (
        <ChainSelectorRow
          disabled
          onSelectChain={() => undefined}
          targetChain={selectorChain}
          key={selectorChain}
          isPending={false}
        />
      ))}
    </div>
  );

  return (
    <div className='relative flex flex-row' ref={ref}>
      <Tooltip
        delayDuration={0}
        open={!isSupported && isTooltipOpen}
        onOpenChange={setIsTooltipOpen}
      >
        <TooltipTrigger
          className='flex cursor-default flex-row items-center justify-center'
          asChild
        >
          <div
            className='cursor-default font-bold text-gray-700/50'
            onClick={handleTooltipToggle}
          >
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={cn(
                'flex flex-row items-center justify-center gap-2',
                'transition-colors duration-300',
                'rounded-lg bg-transparent p-1',
                'hover:bg-blue-800/25'
              )}
            >
              {!isSupported ? (
                <TriangleIcon className='size-5 text-gray-600' />
              ) : (
                <ChainLogo chainId={chainId} size={20} />
              )}

              <ChevronIcon
                className={cn(
                  'size-4 stroke-gray-600 transition-transform duration-300',
                  {
                    'rotate-180': isOpen
                  }
                )}
              />
            </button>
          </div>
        </TooltipTrigger>
        <TooltipContent className='max-w-80 bg-blue-700' side='bottom'>
          Your wallet&apos;s current network is unsupported.
        </TooltipContent>
      </Tooltip>

      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </div>
  );
};

export default ChainSelector;
