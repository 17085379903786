import TokenSafety, { type TokenSafetyProps } from '.';

import Modal from 'components/Modal';

interface TokenSafetyModalProps extends TokenSafetyProps {
  isOpen: boolean;
}

const TokenSafetyModal = ({
  isOpen,
  tokenAddress,
  secondTokenAddress,
  onContinue,
  onCancel,
  onBlocked,
  showCancel
}: TokenSafetyModalProps) => (
  <Modal isOpen={isOpen} onDismiss={onCancel}>
    <TokenSafety
      tokenAddress={tokenAddress}
      secondTokenAddress={secondTokenAddress}
      onContinue={onContinue}
      onBlocked={onBlocked}
      onCancel={onCancel}
      showCancel={showCancel}
    />
  </Modal>
);

export default TokenSafetyModal;
