import { ErrorIcon } from './Logos';
import { PendingModalError } from './constants';

import Button from 'components/ui/Button';

import QuestionHelper from 'components/QuestionHelper';

const getErrorContent = (errorType: PendingModalError) => {
  switch (errorType) {
    case PendingModalError.TOKEN_APPROVAL_ERROR:
      return {
        title: 'Token approval failed',
        label: 'Why are approvals required?',
        tooltipText:
          'This provides the protocol access to your token for trading. For security, this will expire after 30 days.'
      };
    case PendingModalError.PERMIT_ERROR:
      return {
        title: 'Permit approval failed',
        label: 'Why are permits required?',
        tooltipText:
          'Permit2 allows token approvals to be shared and managed across different applications.'
      };
    case PendingModalError.CONFIRMATION_ERROR:
      return {
        title: 'Swap failed'
      };
    case PendingModalError.WRAP_ERROR:
      return {
        title: 'Wrap failed'
      };
  }
};

interface ErrorModalContentProps {
  errorType: PendingModalError;
  onRetry: () => void;
}

export const ErrorModalContent = ({
  errorType,
  onRetry
}: ErrorModalContentProps) => {
  const { title, label, tooltipText } = getErrorContent(errorType);

  return (
    <div className='mb-2 mt-1.5 flex w-full flex-col items-center justify-start gap-6 p-4'>
      <ErrorIcon />

      <div className='flex w-full flex-col items-center justify-start gap-3'>
        <span className='text-medium text-gray-600'>{title}</span>

        <div className='flex flex-row items-center justify-center gap-2'>
          {label && <span className='text-medium text-gray-600'>{label}</span>}
          {tooltipText && <QuestionHelper text={tooltipText} />}
        </div>
      </div>

      <div className='flex w-full flex-row justify-center'>
        <Button variant='secondary' className='w-full' onClick={onRetry}>
          Retry
        </Button>
      </div>
    </div>
  );
};
