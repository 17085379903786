import type { LineItemData, SwapLineItemProps } from './types';
import type { PropsWithChildren } from 'react';
import React, { useState } from 'react';
import { animated } from 'react-spring';

import { cn } from 'utils/cn';

import { useLineItem } from './hooks';
import useHoverProps from 'hooks/useHoverProps';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

export const FOTTooltipContent = () => (
  <>
    Some tokens take a fee when they are bought or sold, which is set by the
    token issuer.
  </>
);

const Loading = () => (
  <div className='w-17.5 loading-shimmer-bg h-3.75 animate-shimmer rounded-xl' />
);

type ValueWrapperProps = PropsWithChildren<{
  lineItem: LineItemData;
  labelHovered: boolean;
  syncing: boolean;
}>;

const ValueWrapper = ({
  children,
  lineItem,
  labelHovered,
  syncing
}: ValueWrapperProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  const { TooltipBody } = lineItem;

  if (syncing) return <Loading />;

  if (!TooltipBody) {
    return (
      <span className='break-words text-right text-xs font-medium text-gray-100'>
        {children}
      </span>
    );
  }

  return (
    <Tooltip
      delayDuration={0}
      open={isTooltipOpen || labelHovered}
      onOpenChange={setIsTooltipOpen}
    >
      <TooltipTrigger className='cursor-default' asChild>
        <div
          className='cursor-default font-bold text-gray-700/50'
          onClick={handleTooltipToggle}
        >
          {children}
        </div>
      </TooltipTrigger>
      <TooltipContent
        className='max-w-80 bg-blue-700 lg:max-w-100'
        side='bottom'
      >
        <TooltipBody />
      </TooltipContent>
    </Tooltip>
  );
};

const SwapLineItem = (props: SwapLineItemProps) => {
  const [labelHovered, hoverProps] = useHoverProps();

  const LineItem = useLineItem(props);
  if (!LineItem) {
    return null;
  }

  return (
    <animated.div style={{ opacity: props.animatedOpacity }}>
      <div className='flex flex-row items-center justify-between gap-2'>
        <span
          className={cn('text-gray-600', {
            'cursor-help': !!LineItem.TooltipBody
          })}
          {...hoverProps}
        >
          <LineItem.Label />
        </span>

        <ValueWrapper
          lineItem={LineItem}
          labelHovered={labelHovered}
          syncing={props.syncing}
        >
          <LineItem.Value />
        </ValueWrapper>
      </div>
    </animated.div>
  );
};

export default React.memo(SwapLineItem);
