import { cn } from 'utils/cn';

export const enum LoadingBubbleDelay {
  None = 'delay-0',
  Short = 'delay-300',
  Medium = 'delay-600',
  Long = 'delay-900'
}

type LoadingBubbleProps = {
  round?: boolean;
  delay?: LoadingBubbleDelay;
  className?: string;
};

export const LoadingBubble = ({
  round,
  delay = LoadingBubbleDelay.None,
  className
}: LoadingBubbleProps) => (
  <div
    className={cn(
      'loading-bubble-bg animate-loading-bubble h-6 w-1/2',
      delay,
      className,
      {
        'rounded-full': round,
        'rounded-xl': !round
      }
    )}
  />
);
