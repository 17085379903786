import type { Percent } from '@uniswap/sdk-core';
import { useMemo, useState } from 'react';

import { cn } from 'utils/cn';
import { NumberType, useFormatter } from 'utils/formatNumbers';
import { warningSeverity } from 'utils/prices';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

import { WarningIcon } from 'components/Icons';
import { LoadingBubble } from 'components/composed/LoadingBubble';

export const FiatValue = ({
  fiatValue,
  priceImpact,
  disabled = false
}: {
  fiatValue: { data?: number; isLoading: boolean };
  priceImpact?: Percent;
  disabled?: boolean;
}) => {
  const [isPriceImpactTooltipOpen, setIsPriceImpactTooltipOpen] =
    useState(false);
  const [isLiquidityTooltipOpen, setIsLiquidityTooltipOpen] = useState(false);

  const handlePriceImpactTooltipToggle = () => {
    if (!disabled) {
      setIsPriceImpactTooltipOpen((prev) => !prev);
    }
  };

  const handleLiquidityTooltipToggle = () => {
    if (!disabled) {
      setIsLiquidityTooltipOpen((prev) => !prev);
    }
  };

  const { formatNumber, formatPercent } = useFormatter();

  const priceImpactColor = useMemo(() => {
    if (!priceImpact) return undefined;
    if (priceImpact.lessThan('0')) return 'text-green';
    const severity = warningSeverity(priceImpact);
    if (severity < 1) return 'text-gray-600';
    if (severity < 3) return 'text-yellow';
    return 'text-red';
  }, [priceImpact]);

  if (fiatValue.isLoading) {
    return <LoadingBubble className='h-4 w-14 rounded-md' />;
  }

  return (
    <div className='flex w-full flex-row items-center justify-start gap-2'>
      <span className='text-sm font-medium text-gray-600'>
        {fiatValue.data ? (
          formatNumber({
            input: fiatValue.data,
            type: NumberType.FiatTokenPrice
          })
        ) : (
          <Tooltip
            delayDuration={0}
            open={!disabled && isLiquidityTooltipOpen}
            onOpenChange={(isOpen) =>
              !disabled && setIsLiquidityTooltipOpen(isOpen)
            }
          >
            <TooltipTrigger className='cursor-default' asChild>
              <div
                className='cursor-default font-bold text-gray-700/50'
                onClick={handleLiquidityTooltipToggle}
              >
                <WarningIcon className='stroke-yellow' />
              </div>
            </TooltipTrigger>
            <TooltipContent className='max-w-80 bg-blue-700' side='bottom'>
              Not enough liquidity to show accurate USD value.
            </TooltipContent>
          </Tooltip>
        )}
      </span>

      {priceImpact && (
        <span className={cn('text-sm font-medium', priceImpactColor)}>
          <Tooltip
            delayDuration={0}
            open={!disabled && isPriceImpactTooltipOpen}
            onOpenChange={(isOpen) =>
              !disabled && setIsPriceImpactTooltipOpen(isOpen)
            }
          >
            <TooltipTrigger className='cursor-default' asChild>
              <div
                className='cursor-default p-2 font-bold text-gray-700/50'
                onClick={handlePriceImpactTooltipToggle}
              >
                ({formatPercent(priceImpact.multiply(-1))})
              </div>
            </TooltipTrigger>
            <TooltipContent className='max-w-80 bg-blue-700' side='bottom'>
              The estimated difference between the USD values of input and
              output amounts.
            </TooltipContent>
          </Tooltip>
        </span>
      )}
    </div>
  );
};
