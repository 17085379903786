import { type ReactNode, useState } from 'react';
import { HelpCircle } from 'react-feather';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

const QuestionHelper = ({ text }: { text: ReactNode; size?: number }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  return (
    <Tooltip
      delayDuration={0}
      open={isTooltipOpen}
      onOpenChange={setIsTooltipOpen}
    >
      <TooltipTrigger className='cursor-default' asChild>
        <div
          className='cursor-default font-bold text-gray-700/50'
          onClick={handleTooltipToggle}
        >
          <HelpCircle className='text-gray-100' size={16} />
        </div>
      </TooltipTrigger>
      <TooltipContent className='max-w-80 bg-blue-700' side='bottom'>
        {text}
      </TooltipContent>
    </Tooltip>
  );
};

export default QuestionHelper;
