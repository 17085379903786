import { useCallback, useRef, useState } from 'react';

export const useClipboard = (timeout: number = 2000) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(true);

        if (copyTimeoutRef.current) {
          clearTimeout(copyTimeoutRef.current);
        }

        copyTimeoutRef.current = setTimeout(() => {
          setIsCopied(false);
        }, timeout);
      });
    },
    [timeout]
  );

  return { isCopied, copyToClipboard };
};
