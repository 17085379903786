import React, { useCallback, useEffect, useRef, useState } from 'react';

import { cn } from 'utils/cn';

type IconButtonProps = {
  onClick: () => void;
  onConfirm?: () => void;
  onDecline?: () => void;
  confirmText?: string;
  icon: React.ComponentType<{ className?: string }>;
  className?: string;
  iconClassName?: string;
};

export const IconButton = ({
  onClick,
  onConfirm,
  onDecline,
  confirmText,
  icon: Icon,
  className,
  iconClassName
}: IconButtonProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (onConfirm) {
      if (showConfirm) {
        onClick();
        setShowConfirm(false);
      } else {
        onConfirm();
        setShowConfirm(true);
      }
    } else {
      onClick();
    }
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowConfirm(false);

        if (onDecline) {
          setTimeout(() => {
            onDecline();
          }, 500);
        }
      }
    },
    [onDecline]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      ref={ref}
      onClick={handleClick}
      className={cn(
        'flex flex-row gap-1',
        'cursor-pointer rounded-full bg-transparent p-1 transition-colors duration-300 hover:bg-gray-100/20',
        className,
        {
          'rounded-1/2 px-2': showConfirm
        }
      )}
    >
      <Icon className={cn('size-6 stroke-gray-600', iconClassName)} />

      {confirmText && (
        <span
          className={cn(
            'overflow-hidden text-gray-600 transition-all duration-300 ease-in-out',
            {
              'max-w-xs opacity-100': showConfirm,
              'max-w-0 opacity-100': !showConfirm
            }
          )}
        >
          {confirmText}
        </span>
      )}
    </div>
  );
};
