import { cn } from 'utils/cn';

import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown';
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp';

const isValidDelta = (delta: number | null | undefined): delta is number =>
  delta !== null && delta !== undefined && delta !== Infinity && !isNaN(delta);

interface DeltaArrowProps {
  delta?: number | null;
  noColor?: boolean;
  size?: number;
}

export const DeltaArrow = ({
  delta,
  noColor = false,
  size = 16
}: DeltaArrowProps) => {
  if (!isValidDelta(delta)) return null;

  return Math.sign(delta) < 0 ? (
    <ArrowChangeDown
      width={size}
      height={size}
      key='arrow-down'
      aria-label='down'
      className={cn({
        'text-gray-600': noColor,
        'text-red': !noColor
      })}
    />
  ) : (
    <ArrowChangeUp
      width={size}
      height={size}
      key='arrow-up'
      aria-label='up'
      className={cn({
        'text-gray-600': noColor,
        'text-green': !noColor
      })}
    />
  );
};
