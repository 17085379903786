import type { Activity } from './types';
import { useCallback } from 'react';

import { shortenAddress } from 'utils';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink';

import { useTimeSince } from './hooks';
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks';
import useENSName from 'hooks/useENSName';

import { PrimaryLargeText, SecondaryText } from 'components/ui/Text';

import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo';
import PortfolioRow from 'components/AccountDrawer/MiniPortfolio/PortfolioRow';
import { TriangleIcon } from 'components/Icons';
import Spinner from 'components/Icons/Spinner';

const StatusIndicator = ({
  activity: { status, timestamp }
}: {
  activity: Activity;
}) => {
  const timeSince = useTimeSince(timestamp);

  switch (status) {
    case TransactionStatus.Pending:
      return <Spinner className='size-6 stroke-blue-200 duration-2000' />;
    case TransactionStatus.Confirmed:
      return <SecondaryText>{timeSince}</SecondaryText>;
    case TransactionStatus.Failed:
      return <TriangleIcon className='size-6 text-yellow' />;
  }
};

export const ActivityRow = ({ activity }: { activity: Activity }) => {
  const {
    chainId,
    title,
    descriptor,
    logos,
    otherAccount,
    currencies,
    hash,
    prefixIconSrc
  } = activity;

  const { ENSName } = useENSName(otherAccount);

  const onClick = useCallback(() => {
    window.open(
      getExplorerLink({
        chainId,
        data: hash,
        type: ExplorerDataType.TRANSACTION
      }),
      '_blank'
    );
  }, [chainId, hash]);

  return (
    <PortfolioRow
      left={
        <div className='flex flex-col justify-start'>
          <PortfolioLogo
            chainId={chainId}
            currencies={currencies}
            images={logos}
            accountAddress={otherAccount}
          />
        </div>
      }
      title={
        <div className='flex w-full items-center justify-start gap-1'>
          {prefixIconSrc && (
            <img height='14px' width='14px' src={prefixIconSrc} alt='' />
          )}
          <PrimaryLargeText>{title}</PrimaryLargeText>
        </div>
      }
      descriptor={
        <SecondaryText className='overflow-hidden text-ellipsis whitespace-nowrap'>
          {descriptor}
          {ENSName ?? shortenAddress(otherAccount)}
        </SecondaryText>
      }
      right={<StatusIndicator activity={activity} />}
      onClick={onClick}
    />
  );
};
