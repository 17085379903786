import { ActivationStatus, useActivationState } from 'connection/activate';

import Button from 'components/ui/Button';
import { PrimaryLargeText, PrimaryXLText } from 'components/ui/Text';

import { useCloseAccountDrawer } from 'components/AccountDrawer';
import { TriangleIcon } from 'components/Icons';

const ConnectionErrorView = () => {
  const { activationState, tryActivation, cancelActivation } =
    useActivationState();
  const closeDrawer = useCloseAccountDrawer();

  if (activationState.status !== ActivationStatus.ERROR) return null;

  const retry = () => tryActivation(activationState.connection, closeDrawer);

  return (
    <div className='flex w-full flex-col items-center justify-center gap-4'>
      <TriangleIcon className='size-20 text-red' />

      <PrimaryXLText weight='bold'>Error connecting</PrimaryXLText>

      <PrimaryLargeText className='text-center'>
        The connection attempt failed. Please click try again and follow the
        steps to connect in your wallet.
      </PrimaryLargeText>

      <Button variant='primary-light' onClick={retry} className='w-full'>
        Try again
      </Button>

      <Button
        variant='primary-light'
        onClick={cancelActivation}
        className='w-full'
      >
        Back to wallet selection
      </Button>
    </div>
  );
};

export default ConnectionErrorView;
