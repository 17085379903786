import type { ChainId } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';

import { cn } from 'utils/cn';

import { useWeb3React } from 'hooks/useWeb3React';

import { CheckIcon } from 'components/Icons';
import Spinner from 'components/Icons/Spinner';
import { ChainLogo } from 'components/Logo/ChainLogo';

type CaptionTextProps = {
  text: string;
};

const CaptionText = ({ text }: CaptionTextProps) => (
  <span className='text-nowrap text-xs font-medium text-gray-600'>{text}</span>
);

interface ChainSelectorRowProps {
  disabled?: boolean;
  targetChain: ChainId;
  onSelectChain: (targetChain: number) => void;
  isPending: boolean;
}
export default function ChainSelectorRow({
  disabled,
  targetChain,
  onSelectChain,
  isPending
}: ChainSelectorRowProps) {
  const { chainId } = useWeb3React();
  const active = chainId === targetChain;
  const chainInfo = getChainInfo(targetChain);
  const label = chainInfo?.label;

  return (
    <button
      disabled={!!disabled}
      onClick={() => {
        if (!disabled) onSelectChain(targetChain);
      }}
      className={cn(
        'flex w-full flex-row items-center gap-2 rounded-lg px-2 py-1 hover:bg-blue-900 md:min-w-60',
        'transition-colors duration-300',
        'border-none bg-none text-left text-sm font-medium text-gray-100',
        'disabled:cursor-auto disabled:opacity-60'
      )}
    >
      <ChainLogo chainId={targetChain} size={20} />

      {label && (
        <span className='text-nowrap text-base font-medium'>{label}</span>
      )}

      {disabled && <CaptionText text='Unsupported by your wallet' />}

      {isPending && <CaptionText text='Approve in wallet' />}

      <div className='flex w-5 flex-row items-center'>
        {active && <CheckIcon className='size-5 text-blue-200' />}

        {!active && isPending && <Spinner className='size-4 stroke-blue-200' />}
      </div>
    </button>
  );
}
