import type { WARNING_LEVEL } from 'constants/tokenSafety';
import type { ReactNode } from 'react';
import { Slash } from 'react-feather';

import { cn } from 'utils/cn';

import {
  useTokenWarningColor,
  useTokenWarningTextColor
} from 'hooks/useTokenWarningColor';

import { TriangleIcon } from 'components/Icons';

type TokenWarningLabelProps = {
  level: WARNING_LEVEL;
  canProceed: boolean;
  children: ReactNode;
};

const TokenSafetyLabel = ({
  level,
  canProceed,
  children
}: TokenWarningLabelProps) => (
  <div
    className={cn(
      'inline-flex items-center gap-2 rounded-lg px-2 py-1 text-sm',
      useTokenWarningTextColor(level),
      useTokenWarningColor(level)
    )}
  >
    <span>{children}</span>

    {canProceed ? (
      <TriangleIcon className='size-4' />
    ) : (
      <Slash strokeWidth={2.5} size='14px' />
    )}
  </div>
);

export default TokenSafetyLabel;
